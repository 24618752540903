<template>
	<div>
		<div class="b-head-block">
			<div class="container">
				<div class="b-head-block__wrapper">
					<h1 class="b-title b-title--h1">Аналитика</h1>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="wrapper" v-if="load">
				<div class="text-info">
					<iconSvg class="b-icon"/>
					<span class="p">Фильтр записей</span>
				</div>
				<div class="filter-nav">
					<div class="select-box">
						<v-select
							:searchable="false"
							class="select"
							:options="options"
							:reduce="country => country.code"
							label="label"
							v-on:input="filterAll(0)"
							v-model="filter.month"
							placeholder="Месяц">
						</v-select>
					</div>
					<div class="select-box">
						<v-select
							class="select"
							:options="quarterOption"
							v-on:input="filterAll(1)"
							v-model="filter.quarter"
							:searchable="false"
							placeholder="Квартал">
						</v-select>
					</div>
					<div class="select-box">
						<v-select
							class="select"
							:options="yearOption"
							v-on:input="filterAll()"
							v-model="filter.year"
							:searchable="false"
							placeholder="Год">
						</v-select>
					</div>
					<div class="select-box">
						<VueDatePicker
					  :max-date="this.date"
						v-on:input="filterAll('period')"
						v-model="filter.date"
						placeholder="Период"
						format="DD.MM.YYYY"
						format-header="DD.MM.YYYY"
						class="date-from"
						range
						range-header-text="От %d до %d"
						range-input-text="От %d до %d"
						fullscreen-mobile/>
					</div>
				</div>
				<h2 class="title">Общая статистика за
				  <span v-if="filter.quarter">{{filter.quarter}} квартал </span>
					<span v-if="filter.month">{{options[filter.month - 1].label}}</span>
					<span v-if="filter.year && filter.month">, </span>
					<span v-if="filter.year">{{filter.year}}</span>
					<span v-if="filter.date">период с {{filter.date.start | moment("DD.MM.YYYY")}} по {{filter.date.end | moment("DD.MM.YYYY")}}:</span>
					<span v-if="!filter.year && !filter.month && !filter.quarter && !filter.date">все время</span>:
				</h2>
				<div class="stats">
					<div class="stats-item stats-header" v-bind:class="{ active:notification }" @click="notification = !notification">
						<span>Уведомления: <b>{{gettersTotal.notificationCount}}</b></span>
						<span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg></span>
					</div>
					<div class="stats-detailed" v-if="notification">
						<div class="stats-item title-item">
							Количество КР с типом подачи "О разработке":
						</div>
						<div class="stats-item">
							<span>Количество реализованных КР:
								<b>{{gettersAnalitics.notification.developProjectsCount.realizedProjectsCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Количество КР в работе:
								<b>{{gettersAnalitics.notification.developProjectsCount.inWorkNotStatementActualProjectsCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Количество нереализованных КР:
								<b>{{gettersAnalitics.notification.developProjectsCount.notRealizedNotStatementNotActualProjectsCount}}</b>
							</span>
						</div>
						<div class="stats-item title-item">
							Количество КР с типом подачи "О пересмотре":
						</div>
						<div class="stats-item">
							<span>Количество реализованных КР:
								<b>{{gettersAnalitics.notification.revisionProjectsCount.realizedProjectsCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Количество КР в работе:
								<b>{{gettersAnalitics.notification.revisionProjectsCount.inWorkNotStatementActualProjectsCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Количество нереализованных КР:
								<b>{{gettersAnalitics.notification.revisionProjectsCount.notRealizedNotStatementNotActualProjectsCount}}</b>
							</span>
						</div>
					</div>
					<div class="stats-item stats-header" v-bind:class="{ active:application }" @click="application = !application">
						<span>Заявления: <b>{{gettersTotal.applicationCount}}</b></span>
						<span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg></span>
					</div>
					<div class="stats-detailed" v-if="application">
						<div class="stats-item title-item">
							Количество КР с типом подачи "О разработке":
						</div>
						<div class="stats-item">
							<span>Отправлено в ЦЭККМП:
								<b>{{gettersAnalitics.application.developProjectsCount.sentToCEKKMPStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Отправлен на доработку:
								<b>{{gettersAnalitics.application.developProjectsCount.sentForRevisionStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>В работе:
								<b>{{gettersAnalitics.application.developProjectsCount.inProgress}}</b>
							</span>
						</div>
						<div class="stats-item title-item">
							Количество КР с типом подачи "О доработке":
						</div>
						<div class="stats-item">
							<span>Отправлено в ЦЭККМП:
								<b>{{gettersAnalitics.application.refinementProjectsCount.sentToCEKKMPStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Отправлен на доработку:
								<b>{{gettersAnalitics.application.refinementProjectsCount.sentForRevisionStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>В работе:
								<b>{{gettersAnalitics.application.refinementProjectsCount.inProgress}}</b>
							</span>
						</div>
						<div class="stats-item title-item">
							Количество КР с типом подачи "О пересмотре":
						</div>
						<div class="stats-item">
							<span>Отправлено в ЦЭККМП:
								<b>{{gettersAnalitics.application.revisionProjectsCount.sentToCEKKMPStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>Отправлен на доработку:
								<b>{{gettersAnalitics.application.revisionProjectsCount.sentForRevisionStatusCount}}</b>
							</span>
						</div>
						<div class="stats-item">
							<span>В работе:
								<b>{{gettersAnalitics.application.revisionProjectsCount.inProgress}}</b>
							</span>
						</div>
					</div>
					<div class="stats-item-info">
						<span>Экспертная оценка КР: <b>{{gettersTotal.expertReviewCount}}</b></span>
					</div>
					<div class="stats-item-info">
						<span>Решение НПС: <b>{{gettersTotal.npsDecisionCount}}</b></span>
					</div>
				</div>
					<div class="chart">
						<div class="menu">
							<div class="menu-btn" @click="notificationMenu = !notificationMenu">
								<span></span>
								<span></span>
								<span></span>
							</div>
							<div v-bind:class="{active: notificationMenu, menu_not: true }">
								<ul>
									<li v-bind:class="{active: !apexKey}" @click="notificationSerias(true)">О разработке</li>
									<li v-bind:class="{active: apexKey}" @click="notificationSerias(false)">О пересмотре</li>
								</ul>
							</div>
						</div>
						<apexchart type="bar" height="180" :key="apexKey" :options="chartOptionsNotification" :series="gettersNotification"></apexchart>
					</div>
					<div class="chart">
						<div class="menu">
							<div class="menu-btn" @click="aplicationMenu = !aplicationMenu">
								<span></span>
								<span></span>
								<span></span>
							</div>
							<div v-bind:class="{active: aplicationMenu, menu_not: true }">
								<ul>
									<li v-for="(application, index) in applicationSeries" :key="application.value" v-bind:class="{active: application.active}" @click="applicationSerias(application.value, index)">{{application.text}}</li>
								</ul>
							</div>
						</div>
						<apexchart type="bar" height="180" :key="apexKey" :options="chartOptionsApplication" :series="gettersApplication"></apexchart>
					</div>
					<div class="chart">
						<apexchart type="bar" height="140" :key="apexKey" :options="chartOptionsExpertReview" :series="gettersExpertReview"></apexchart>
					</div>
					<div class="chart">
						<apexchart type="bar" height="180" :key="apexKey"  :options="chartOptionsDecision" :series="gettersDecision"></apexchart>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import iconSvg from "../../assets/icons/svg/filter.svg"

const ru = require("apexcharts/dist/locales/ru.json")

export default {
	name: 'Analytics',
	components: { VueApexCharts, VueDatePicker, iconSvg },
	computed: {
		...mapGetters({
				gettersAnalitics: 'analytics/gettersAnalitics',
				gettersNotification: 'analytics/gettersNotification',
				gettersApplication: 'analytics/gettersApplication',
				gettersTotal: 'analytics/gettersTotal',
				gettersExpertReview: 'analytics/gettersExpertReview',
				gettersDecision: 'analytics/gettersDecision',
				getUserInfo: 'auth/getUserInfo',
				gettersMax: 'analytics/gettersMax',
		}),
	},
	data: () => ({
		apexKey: 0,
		load: true,
		notificationMenu: false,
		aplicationMenu: false,
		notification: false,
		decisionInfo: false,
		application: false,
		expertReview: false,
		period: false,
		filter: {
			month: null,
			quarter: null,
			year: null,
			date: '',
		},
		applicationSeries: [
			{text: 'О разработке', active: true, value: 'developProjectsCount'},
			{text: 'О Пересмотре', active: false, value: 'revisionProjectsCount'},
			{text: 'О доработке', active: false, value: 'refinementProjectsCount'},
		],
		options: [
			{code: 1, label: "Январь"},
			{code: 2, label: "Февраль"},
			{code: 3, label: "Март"},
			{code: 4, label: "Апрель"},
			{code: 5, label: "Май"},
			{code: 6, label: "Июнь"},
			{code: 7, label: "Июль"},
			{code: 8, label: "Август"},
			{code: 9, label: "Сентябрь"},
			{code: 10, label: "Октябрь"},
			{code: 11, label: "Ноябрь"},
			{code: 12, label: "Декабрь"},
		],
		quarterOption: [1,2,3,4],
		yearOption: [2019,2020,2021,2022,2023],
		date: new Date(),
		chartOptionsNotification: {
			chart: {
				group: 'states',
			  locales: [ru],
			  defaultLocale: 'ru',
				type: 'bar',
				height: 380,
			},
			legend: {
				show: false,
			},
			plotOptions: {
				bar: {
					barHeight: '100%',
					distributed: true,
					horizontal: true,
					dataLabels: {
						position: 'bottom',
					},
				}
			},
			colors: ['#2EB4B4', '#ABB7CE', '#F86A6F'],
			dataLabels: {
				enabled: true,
				textAnchor: 'middle',
				style: {
					colors: ['#04092a'],
					fontSize: '14px',
				},
				formatter: function (val, opt) {
					return val
				},
				offsetX: 5,
				dropShadow: {
					enabled: false
				}
			},
			legend: {
	      show: true,
	      showForSingleSeries: true,
	      customLegendItems: ['Реализовано', 'В работе', 'Просрочено'],
	      markers: {
	        fillColors: ['#2EB4B4', '#ABB7CE', '#F86A6F']
	      }
	    },
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories: ['Реализовано', 'В работе', 'Просрочено'],
				max: 100,
				labels: {
					show: false
				}
			},
			yaxis: {
				labels: {
					minWidth: 1,
					show: false
				}
			},
			title: {
				text: 'Уведомления: О разработке',
				align: 'center',
				floating: true
			},
			tooltip: {
				enabled: false,
			}
		},
		chartOptionsApplication: {
			chart: {
				group: 'states',
			  locales: [ru],
			  defaultLocale: 'ru',
				type: 'bar',
				height: 380,
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ['Отправлено в ЦЭККМП', 'В работе', 'Отправлен на доработку'],
				markers: {
					fillColors: ['#2EB4B4', '#ABB7CE', '#F86A6F']
				}
            },
			plotOptions: {
				bar: {
					barHeight: '100%',
					distributed: true,
					horizontal: true,
					dataLabels: {
						position: 'bottom'
					},
				}
			},
			colors: ['#2EB4B4', '#ABB7CE', '#F86A6F', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
				'#f48024', '#69d2e7'
			],
			dataLabels: {
				enabled: true,
				textAnchor: 'middle',
				style: {
					colors: ['#04092a'],
					fontSize: '14px',
				},
				formatter: function (val, opt) {
					return val
				},
				offsetX: 5,
				dropShadow: {
					enabled: false
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				categories: ['Отправлено в ЦЭККМП', 'Отправлено на доработку'],
				max: 100,
				labels: {
					show: false
				}
			},
			yaxis: {
				labels: {
					minWidth: 1,
					show: false
				}
			},
			title: {
				text: 'Заявления: О разработке',
				align: 'center',
				floating: true
			},
			tooltip: {
				enabled: false,
			}
		},
		chartOptionsExpertReview: {
			chart: {
				group: 'states',
			  locales: [ru],
			  defaultLocale: 'ru',
				type: 'bar',
				height: 380,
			},
					legend: {
				show: false,
					},
			plotOptions: {
				bar: {
				barHeight: '100%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'bottom'
				},
				}
			},
			colors: ['#2EB4B4', '#ABB7CE'],
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ['Успешно', 'С замечаниями'],
				markers: {
					fillColors: ['#2EB4B4', '#ABB7CE']
				}
            },
			dataLabels: {
				enabled: true,
				textAnchor: 'middle',
				style: {
					colors: ['#04092a'],
					fontSize: '14px',
				},
				formatter: function (val, opt) {
					return val
				},
				offsetX: 5,
				dropShadow: {
					enabled: false
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				max: 100,
				categories: ['Разработка', 'Пересмотр', 'Доработка'],
				labels: {
					show: false
				}
			},
			yaxis: {
				labels: {
					minWidth: 1,
					show: false
				}
			},
			title: {
				text: 'Экспертная оценка КР',
				align: 'center',
				floating: true
			},
			tooltip: {
				enabled: false,
			}
		},
		chartOptionsDecision: {
			chart: {
				group: 'states',
			  locales: [ru],
			  defaultLocale: 'ru',
				type: 'bar',
				height: 380,
			},
					legend: {
				show: false,
					},
			plotOptions: {
				bar: {
				barHeight: '100%',
				distributed: true,
				horizontal: true,
				dataLabels: {
					position: 'bottom'
				},
				}
			},
			colors: ['#2EB4B4', '#ABB7CE', '#F86A6F'],
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ['Одобрено', 'На доработку', 'Отклонено'],
				markers: {
					fillColors: ['#2EB4B4', '#ABB7CE', '#F86A6F']
				}
            },
			dataLabels: {
				enabled: true,
				textAnchor: 'middle',
				style: {
					colors: ['#04092a'],
					fontSize: '14px',
				},
				formatter: function (val, opt) {
					return val
				},
				offsetX: 5,
				dropShadow: {
					enabled: false
				}
			},
			stroke: {
				width: 1,
				colors: ['#fff']
			},
			xaxis: {
				max: 100,
				labels: {
					show: false
				},
				categories: ['Разработка', 'Пересмотр', 'Доработка'],
			},
			yaxis: {
				labels: {
					minWidth: 1,
					show: false
				}
			},
			title: {
				text: 'Решение НПС',
				align: 'center',
				floating: true
			},
			tooltip: {
				enabled: false,
			}
		}
	}),
	methods: {
		...mapActions({
			projectNotificationStageInfo: 'analytics/projectNotificationStageInfo',
			projectApplicationStageInfo: 'analytics/projectApplicationStageInfo',
			projectExpertReviewInfo: 'analytics/projectExpertReviewInfo',
			projectNPSDecisionInfo: 'analytics/projectNPSDecisionInfo',
			projectTotalCount: 'analytics/projectTotalCount',
		}),
		...mapMutations({
			mutationNotificationSerias: 'analytics/mutationNotificationSerias',
			mutationApplicationSerias: 'analytics/mutationApplicationSerias'
		}),
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());
			return local.toJSON().slice(0, 10);
		},
		getSeries(option) {
			// если МНПКО то фильтруем
			if(this.getUserInfo.role.id == 1) {
				option.userRoleId = this.getUserInfo.role.id,
				option.userCompanyId = this.getUserInfo.company.id
			}
			this.projectExpertReviewInfo(option)
			this.projectNPSDecisionInfo(option)
			this.projectApplicationStageInfo(option)
			this.projectTotalCount(option)
			this.projectNotificationStageInfo(option).then(res => {
				this.maxXaxis()
				this.apexKey++
			})
		},
		maxXaxis() {
			if (this.gettersMax > 60) {
				this.chartOptionsNotification.xaxis.max = this.gettersMax
				this.chartOptionsApplication.xaxis.max = this.gettersMax
				this.chartOptionsExpertReview.xaxis.max = this.gettersMax
				this.chartOptionsDecision.xaxis.max = this.gettersMax
			}
		},
		notificationSerias(bool) {
			this.chartOptionsNotification.title.text = bool ? 'Уведомления: О разработке' : 'Уведомления: О пересмотре'
			this.maxXaxis()
			this.apexKey = bool ? 0 : 1
			this.notificationMenu = !this.notificationMenu
			this.mutationNotificationSerias(bool)
		},
		applicationSerias(value, index) {
			this.chartOptionsApplication.title.text = `Заявления: ${this.applicationSeries[index].text}`
			this.applicationSeries.map((item, index) => {
				this.applicationSeries[index].active = false
			})
			this.aplicationMenu = !this.aplicationMenu
			this.applicationSeries[index].active = true
			this.maxXaxis()
			this.apexKey++
			this.mutationApplicationSerias(value)
		},
		filterAll(option) {
			let filterParams = {};
			if (option === 'period') {
				
				console.log('if period', this.filter.month, this.filter.quarter, this.filter.year);

				filterParams = {
					"firstDate": this.filter.date.start,
					"lastDate": this.filter.date.end,
				}
				this.filter.month = null
				this.filter.quarter = null
				this.filter.year = null

			} else {
				// console.log('else', this.filter.month, this.filter.quarter, this.filter.year);

				this.filter.date = ''

				filterParams = {
					"month": this.filter.month,
					"quarter": this.filter.quarter,
					"year": this.filter.year,
				}
			}

			this.getSeries(filterParams)
		}
	},
	mounted() {
		this.getSeries({})
	}
}
</script>
<style lang='less'>
@import "./style/mobile.less";

@media (min-width: 768px) {
    @import "./style/tablet.less";
}

@media (min-width: 1366px) {
    @import "./style/desktop.less";
}
</style>
